@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif !important;
}

.latoFont{
    font-family: 'Lato', sans-serif !important;
}

.basket{
    background: linear-gradient(#0c0c0c,#1e1e1e);
    color: #fff;
    padding-top: 1.5rem;
    border-top: 1px solid #e5bc42;

    &__header{
        padding: .75rem;
        border-bottom: 1px solid black;
    }

    &__body{

        //border-bottom: 1px solid black;
        &--item{
            padding: .5rem;
        }
    }

    &__footer{
        padding: .75rem;
    }

    &--input{
        display: inline-block;
        width: 60px;
        height: 60px;
        position: relative;
        left: calc(50% - 30px);
        background: #fff;
        border: 2px solid #e5bc42;
        color: #7f7f7f;
        text-align: center;
        font: 600 1.5rem Helvetica, Arial, sans-serif;
        border-radius: 2rem;
        outline: none;
    }

    &--img{
        // width: 100%;
        max-height: 200px;
    }

    &--box-img{
        text-align: center;
    }

    @media only screen and (max-width: 991px) {
        &--img{
            // width: 100%;
            max-height: 150px;
            // margin-left: 1rem;
        }
        &--box-img{
            text-align: left;
        }
    }
}

.hoverRed:hover{
    color: red;
}